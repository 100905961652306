const data = [
  {
    coverImage: "rosa_maria_malet.jpg",
    coverText: "rosa_maria_malet.svg",
    menu: [
      { title: "Option 1", video: "1.mp4" },
      { title: "Option 2", video: "2.mp4" },
      { title: "Option 3", video: "3.mp4" },
      { title: "Option 4", video: "4.mp4" },
      { title: "Option 5", video: "5.mp4" }
    ]
  }
]

// 0: Joan Punyet Miro
// 1: Rosa Maria Malet
// 2: Joan Baixas
// 3: Isabel Steva Colita
export const DATA = data[0];
