<template>
  <section @click="router.push({ name: 'menu' })"
    :style="{ '--image': `url('` + require('../assets/covers/' + DATA.coverImage) + `')` }">
    <img :src="require('../assets/covers/' + DATA.coverText)">
  </section>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { DATA } from '../assets/data.js'

const router = useRouter();
</script>

<style scoped>
section {
  background: linear-gradient(180deg, #00000000 30%, rgb(0, 0, 0) 100%), var(--image);
  background-repeat: no-repeat;
  background-size: contain;
}

img {
  bottom: 0;
  left: 0;
  position: fixed;
  height: 43%;
}
</style>